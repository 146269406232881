<template>
  <div class="page_container">
    <section>
      <div class="row">
        <div class="pt-35 pb-5">
          <h1 class="my-0">Support Tickets</h1>
        </div>
      </div>
      <hr class="mt-15" />
      <section class="py-20 gap-20 col">
        <div class="weight-300 min-38">
          Online support ticketing system for SecurCom products. In case of any
          issue related to our products, licenses, invoicing or when you need to
          contact us with other requests.
        </div>
      </section>
      <section class="d-flex py-15 space-between align-center">
        <div class="d-flex searchBar" title="Start typing to search for a support ticket">
          <s-text-field
            v-model="search"
            ref="search"
            :placeholder="$t('attendees.search')"
            block
          ></s-text-field>
          <div class="box center pointer" @click="focus()">
            <s-icon color="grey" class="">account-search-outline</s-icon>
          </div>
        </div>
        <div>
          <s-btn
            @click.native="addLicense"
            class="green addButton ml-10"
            :title="'Add license'"
          >
            <s-icon width="21" class="pr-10" color="white"
              >ticket-confirmation-outline</s-icon
            >
            <div class="grow">
              <div>Open new ticket</div>
            </div>
          </s-btn>
        </div>
      </section>
      <hr />
    </section>
    <Table :search="search" />
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import Table from "./tables/SupportTable";
export default {
  components: {
    Table,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    addLicense() {
      this.$store.commit("modals/supportTicket", true);
    },
    focus() {
      this.$refs.search.onFocus();
    },
  },
  computed: {
    ...mapState("supportTickets", ["categories"]),
  },

  async mounted() {
    await this.$store.dispatch("supportTickets/getSupportTickets");
    await this.$store.dispatch("supportTickets/getSupportCategories");
  },
};
</script>
  
  <style lang="less" scoped>
.table {
  max-height: calc(100vh - 411px);
  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 110px;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
}

.grid:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.details_grid {
  display: grid;
  grid-template-columns: 160px 160px;
  max-height: 200px;
  grid-row-gap: 5px;
}

.item {
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.admin {
  font-size: 12px;
  color: #53bdc1;
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.searchBar {
  max-width: 480px;
  width: 100%;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.addButton {
  min-width: min-content;
  margin-left: 15px;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

.min-38 {
  min-height: 38px;
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>